import React from "react";
import {Datagrid, List, TextField} from 'react-admin';
import SearchEmailFilter from "../../SearchFilter/SearchEmailFilter";

const UserList : React.FC = user => {
    return (
      <List {...user} title={"Lista de Usuários"} filters={<SearchEmailFilter/>}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="email" sortable={false} />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="city" />
          <TextField source="uf" />
        </Datagrid>
      </List>
    )
}

export default UserList;
