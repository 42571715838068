import {AuthProvider} from "react-admin";

const authProvider: AuthProvider = {
  login: ({username, password}: any) => {
    const request = new Request(`${process.env.REACT_APP_API}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({email: username, password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(auth => {
        if (!auth.user.isAdmin) throw new Error("Not an admin!");
        console.log(auth)
        localStorage.setItem('auth', JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error('Network error')
      });
  },
  checkAuth(): Promise<void> {
    if (!localStorage.hasOwnProperty('auth')) return Promise.reject()
    const userToken = JSON.parse(localStorage.getItem('auth')!!);
    const request = new Request(`${process.env.REACT_APP_API}/auth`, {
      method: 'GET',
      headers: new Headers({'Authorization': `Bearer ${userToken.token}`}),
    });

    return fetch(request)
      .then(auth => {
        if (auth.status !== 200) return Promise.reject()
        return Promise.resolve()
      })
      .catch(() => Promise.reject())
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions(): Promise<any> {
    return Promise.resolve();
  },
  logout(): Promise<void | false | string> {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
};

export default authProvider;