import {DataProvider, fetchUtils, GetListParams, GetListResult} from "react-admin";
import simpleRestProvider from 'ra-data-simple-rest';
import UrlBuilder from "../utils/UrlBuilder";

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const {token} = JSON.parse(localStorage.getItem('auth') as string);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider('http://localhost:3000', httpClient);

const customDataProvider: DataProvider = {
  ...dataProvider,
  getList(resource: string, params: GetListParams): Promise<GetListResult<any>> {
    const {page, perPage} = params.pagination;
    const {order, field} = params.sort
    const {q} = params.filter

    const url = new UrlBuilder(resource).page(page).pageSize(perPage).sort(field).order(order).query(q).build()

    return new Promise((resolve, reject) => {
      httpClient(url)
        .then(value => {
          return resolve({data: value.json.content, total: value.json.numberOfElements})
        })
        .catch(reason => {
          return reject(reason)
        })
    })
  },
}

export default customDataProvider