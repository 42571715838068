import './App.css';
import {Admin, Resource} from "react-admin";
import StoryList from "./components/story/StoryList/StoryList";
import authProvider from "./provider/AuthProvider";
import dataProvider from "./provider/DataProvider";
import UserList from "./components/user/UserList/UserList";

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="story" list={StoryList} />
      <Resource name="user" list={UserList} />
    </Admin>
  );
}

export default App;
