import React from "react";
import {BooleanField, Datagrid, List, RichTextField, TextField} from 'react-admin';
import SearchIdFilter from "../../SearchFilter/SearchIdFilter";

const StoryList : React.FC = story => {
  return (
    <List {...story} title={"Lista de Stories"} filters={<SearchIdFilter/>}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <RichTextField source="message" />
        <TextField source="views" />
        <BooleanField source="isPublic" label="Pública" />
      </Datagrid>
    </List>
  )
}

export default StoryList;
