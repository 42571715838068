class UrlBuilder {

  baseUrl : string = `${process.env.REACT_APP_API}/`

  constructor(resource : string) {
    this.baseUrl = this.baseUrl + resource
  }

  page(page : number = 1) : UrlBuilder {
    this.baseUrl = this.baseUrl + `?page=${page}`
    return this
  }

  pageSize(pageSize : number = 10) : UrlBuilder {
    this.baseUrl = this.baseUrl + `&pageSize=${pageSize}`
    return this
  }

  sort(sort? : string) : UrlBuilder {
    if (!sort) return this
    this.baseUrl = this.baseUrl + `&sort=${sort}`
    return this
  }

  order(order? : string) : UrlBuilder {
    if (!order) return this
    this.baseUrl = this.baseUrl + `&order=${order}`
    return this
  }

  query(query? : string) : UrlBuilder {
    if (!query) return this
    this.baseUrl = this.baseUrl + `&q=${query}`
    return this
  }

  build() : string {
    return this.baseUrl
  }

}

export default UrlBuilder